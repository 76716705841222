import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
    state() {
        return {
            base_url: 'https://tmsapipost.areteonline.in/',
            category: [],
            subcategory: [],
            items: [],
            offerlist: [],
            MostPopular: [],
            newsroomData: [], // Store fetched data
            Blogs: [], // Store fetched data
            blogDetails:[],
            aboutview:[],
            CSRview:[],
            CSRValues:[],
            RealState:[],
            Hospitality:[],
            user: null,  // Make sure to initialize user state
        };
    },
    mutations: {
        SET_NEWSROOM(state, data) {
            state.newsroomData = data;
        },
        SET_BLOGS(state, data) {
            state.Blogs = data;
        },
        SET_BLOG_DETAILS(state, blogDetails) {
            state.blogDetails = blogDetails;
        },
        SET_ABOUT_DETAILS(state, aboutvies) {
            state.aboutview = aboutvies;
        },
        SET_CSR_DETAILS(state, CSRvies) {
            state.CSRview = CSRvies;
        },
        SET_VALUES_DETAILS(state, valuies){
            state.CSRValues = valuies;
        },
        SET_REALSTATE_DETAILS(state, statet){
            state.RealState = statet;
        },
        SET_Hospitality_DETAILS(state, statet){
          state.Hospitality = statet;
      }
    },
    actions: {
        async submitdata({ commit }, payload) {
            console.log('------store---', payload);
            const optionAxios = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            try {
                const res = await axios.post('https://emailservices.areteonline.in/index.php/enquire-email', payload, optionAxios);
                alert('success');
                commit('setUser', res);  // Corrected here
            } catch (error) {
                // handle error
                console.log(error);
            } finally {
                // always executed
                console.log('--------s-');
            }
        },
        async submitMediaKit({ commit }, payload) {
            console.log('------store---', payload);
            const optionAxios = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            try {
                const res = await axios.post('https://emailservices.areteonline.in/index.php/mediaKit-email', payload, optionAxios);
                alert('success');
                commit('setUser', res);  // Corrected here
            } catch (error) {
                // handle error
                console.log(error);
            } finally {
                // always executed
                console.log('--------s-');
            }
        },
        async fetchNewsroom({ commit }) {
            try {
              const response = await axios.get('https://cms.aretegroup.in/index.php/api/tasklist'); // Replace with your API
              commit('SET_NEWSROOM', response.data); // Commit data to mutation
            } catch (error) {
              console.error('Error fetching newsroom data:', error);
            }
          },
          async fetchBlogs({ commit }) {
            try {
              const response = await axios.get('https://cms.aretegroup.in/index.php/api/Blogslist'); // Replace with your API
              commit('SET_BLOGS', response.data); // Commit data to mutation
            } catch (error) {
              console.error('Error fetching SET_BLOGS data:', error);
            }
          },
          async fetchBlogDetails({ commit },id) {
            try {
                const response = await axios.get(`https://cms.aretegroup.in/index.php/api/BlogsRecord/${id}`); // Example API
                // console.log("Fetched Blog Details:", response.data[0]);
                commit('SET_BLOG_DETAILS', response.data[0]); // Commit data to mutation
            } catch (error) {
              console.error('Error fetching SET_BLOGSDetails data:', error);
            }
          },
          async fetchActionAbout({ commit }) {
            try {
                const response = await axios.get(`https://cms.aretegroup.in/index.php/api/about-details`); // Example API
                // console.log("Fetched Blog Details:", response.data[0]);
                commit('SET_ABOUT_DETAILS', response.data[0]); // Commit data to mutation
            } catch (error) {
              console.error('Error fetching SET_ABOUT_DETAILS data:', error);
            }
          },
          async fetchActionCSR({ commit }) {
            try {
                const response = await axios.get(`https://cms.aretegroup.in/index.php/api/CSR-details`); // Example API
                // console.log("Fetched Blog Details:", response.data[0]);
                commit('SET_CSR_DETAILS', response.data[0]); // Commit data to mutation
            } catch (error) {
              console.error('Error fetching SET_CSR_DETAILS data:', error);
            }
          },
          async fetchActionValues({ commit }) {
            try {
                const response = await axios.get(`https://cms.aretegroup.in/index.php/api/values-data-details`); // Example API
               // console.log("Fetched Values Details:", response.data[0]);
                commit('SET_VALUES_DETAILS', response.data[0]); // Commit data to mutation
            } catch (error) {
              console.error('Error fetching SET_VALUES_DETAILS data:', error);
            }
          },
          async fetchActionRealstate({ commit }) {
            try {
                const response = await axios.get(`https://cms.aretegroup.in/index.php/api/Real-Estate-details`); // Example API
               // console.log("Fetched Values Details:", response.data[0]);
                commit('SET_REALSTATE_DETAILS', response.data[0]); // Commit data to mutation
            } catch (error) {
              console.error('Error fetching SET_REALSTATE_DETAILS data:', error);
            }
          },
          async fetchActionHospitality({ commit }) {
            try {
                const response = await axios.get(`https://cms.aretegroup.in/index.php/api/Hospitality-details`); // Example API
               // console.log("Fetched Values Details:", response.data[0]);
                commit('SET_Hospitality_DETAILS', response.data[0]); // Commit data to mutation
            } catch (error) {
              console.error('Error fetching SET_Hospitality_DETAILS data:', error);
            }
          },
    },
    getters: {
        // Add any getters if needed
        fetchNewsroom(state) {
            return state.newsroomData;
          },
        fetchBlogs(state) {
            return state.Blogs;
          },
        fetchBlogDetails(state){
            return state.blogDetails;
          },
        fetchAbout(state){
            return state.aboutview;
        },
        fetchCSR(state){
            return state.CSRview;
        },
        fetchValues(state){
            return state.CSRValues;
        },
        fetchRealState(state){
            return state.RealState;
        },
        fetchHospitality(state){
          return state.Hospitality;
        }
    }
});

export default store;
